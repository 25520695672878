.sleepSwitch {
    position: relative;
    width: 80px;
    height: 48px;
    background: #006d5b;
    border-radius: 40px;
    cursor: pointer;
  }
  .slider::before {
    content: "";
    display: flex;
    position: absolute;
    top: 6px;
    left: 4px;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    background-color: #181818;
    transition: 0.4s;
  }
  .sleepInput {
    position: absolute;
    display: none;
  }
  .slider {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: 0.4s;
  }
  .sleepInput:checked ~ .slider {
    background-color: #006d5b;
  }
  
  .sleepInput:checked ~ .slider::before {
    transform: translateX(36px);
    background-color: #d10a2c;
  }
  
